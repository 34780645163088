import React, { useEffect, useState } from "react";
import { styled } from "linaria/react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { Button } from "@project/shared";
import { theme } from "@project/shared/src/theme";

const Wrapper = styled.div`
  background-color: rgba(34, 34, 34, 0.6);
  position: sticky;
  bottom: 0px;
  z-index: 1000;
`;

const CookiesContainer = styled.div`
  border-radius: 8px;
  padding: 24px 0px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 1300px) {
    padding: 24px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
  & .message {
    display: flex;
    flex-direction: column;
  }
  & a {
    color: white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    &:hover {
      color: ${theme.buttonRed};
    }
    /* or 150% */
  }
  & .w1447jll .ant-btn-primary {
    height: 48px;

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;
const CookiesMessage = styled.div`
  color: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
`;
const ButtonContainer = styled.div`
  display: flex;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const CookiesCard = () => {
  const { t } = useTranslation();
  const [showCookiesMessage, setShowCookiesMessage] = useState(true);
  const [hide, setHide] = useState(true);
  useEffect(() => {
    const acceptValue = localStorage.getItem("accept");

    if (acceptValue == "1") {
      setShowCookiesMessage(false);
    }
    setHide(false);
  }, []);
  return (
    <>
      {showCookiesMessage && !hide && (
        <Wrapper>
          <CookiesContainer>
            <div className="message">
              <CookiesMessage>
                {t(
                  "We are always working to improve this website for our users. To do this, we use the anonymous data provided by cookies."
                )}
              </CookiesMessage>
              <Link href="/policy">
                <a target="_blank">{t("Read cookies policy")}</a>
              </Link>
            </div>
            <ButtonContainer>
              <Button
                onClick={() => {
                  localStorage.setItem("accept", "1");
                  setShowCookiesMessage(false);
                }}
                minheight={48}
                minwidth={124}
                padding="0px"
                type="primary"
              >
                {t("Accept All")}
              </Button>
            </ButtonContainer>
          </CookiesContainer>
        </Wrapper>
      )}
    </>
  );
};

export { CookiesCard };
